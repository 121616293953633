import { ModuleManagerState } from "@/includes/logic/Modules/ModulesManager";
import { successNotification } from "@/includes/NotificationService";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import { isChatManager } from "@/includes/logic/Modules/types/predicates";

import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Route } from "vue-router";
import { NavigationGuardNext } from "vue-router/types/router";

Component.registerHooks([
  'beforeRouteLeave'
])

// TODO:: TEMPORARY MIXIN, WILL BE REMOVED AFTER FULL MODULES MIGRATE. NEEDED METHODS THEN ARE COMMENTED IN CURRENT-MODULE.VUE
@Component({
  data() {
    return {
      ModuleManagerState
    }
  },
})
export default class TempCurrentModuleMixin extends Vue {
  saveModuleSettingsConfirm(onOk: () => void, onCancel: () => void) {
    this.$confirm({
      title: this.$t('confirm_save_before_leave_title').toString(),
      content: this.$t('confirm_save_before_leave_content').toString(),
      cancelText: this.$t('confirm_save_before_leave_cancel').toString(),
      okText: this.$t('save_button').toString(),
      onOk: () => {
        onOk()
      },
      onCancel: () => {
        onCancel()
      }
    })
  }

  beforeRouteLeave(to: Route, from:Route, next: NavigationGuardNext): void {
    if (ModuleManagerState && ModuleManagerState.currentModule) {
      if (ModuleManagerState.isCurrentModuleChanged && !ModuleManagerState.currentModule.hasLimits() && ModuleManagerState.currentModule.isSaveable() && !ModuleManagerState.currentModule.tariffTags()) {
        const onOk = () => this.saveModule()
            .finally(() => {
              next()
            })

        const onCancel = () => {
          next()

          ModuleManagerState!.clearCurrentModule()
        }

        this.saveModuleSettingsConfirm(onOk, onCancel)
      } else {
        ModuleManagerState!.clearCurrentModule()
        next()
      }
    } else {
      next()
    }
  }

  saveModule() {
    const currentModuleExistsInChat = ModuleManagerState!.isCurrentModuleSaved;

    return ModuleManagerState!.saveModule(ModuleManagerState!.currentModule!)
        .then(res => {
          if (res) {
            ModuleManagerState!.updatedModuleGuid = ModuleManagerState!.currentModule?.model?.guid ?? null

            successNotification(this.$t('module_notification_module_save').toString())
          }
        })
  }

  // todo
  get isChatManagerVm() {
    return isChatManager(ModuleManagerState)
  }

  get chatId() {
    if (this.isChatManagerVm) {
      return this.$store.getters.isChatSet && this.$store.getters.chatId
    } else {
      const network = this.$store.state.networksState.currentNetwork

      return network && network.chats.length && network.chats[0]
    }
  }

  setCurrentModule(type: ModuleTypesEnum) {
    if (ModuleManagerState) {
      const module = ModuleManagerState.getModuleByCriteria('type', type)

      if (module) {
        ModuleManagerState.prepareModuleForEdit(module)
      } else {
        ModuleManagerState.getDefaultModule(this.chatId, type)
            .then((model) => {
              if (model) {
                ModuleManagerState!.prepareModuleForEdit(model)
              }
            })
      }
    }
  }
}
