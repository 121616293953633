



















import { ModuleManagerState } from '@/includes/logic/Modules/ModulesManager'

import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

// import Vue from 'vue'
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'
// import { Route } from 'vue-router'
// import { NavigationGuardNext } from 'vue-router/types/router'
import TempCurrentModuleMixin from '@/includes/logic/Modules/mixins/TempCurrentModuleMixin'
// import { AdditionalReplacerFn, initialiseAdditionalReplacer } from 'piramis-base-components/src/components/SelectEntityWizard/includes/helpers'

@Component({
  data() {
    return {
      ModuleManagerState
    }
  },
  components: {
    PageTitle
  },
})
export default class CurrentModule extends Mixins(TempCurrentModuleMixin) {

  @Prop({ type: Boolean, required: false, default: false }) hasBackButton!: boolean

  @Prop({ type: String, required: false }) backButtonTitle!:string

  @Emit()
  onBackClick() {
    return null
  }

  get currentModule() {
    return ModuleManagerState?.currentModule
  }

  get currentModuleView() {
    if (ModuleManagerState && ModuleManagerState.currentModule) {
      return ModuleManagerState.currentModule.view
    }
  }

  // saveModule() {
  //   const currentModuleExistsInChat = ModuleManagerState!.currentModuleExistsInChat;
  //
  //   return ModuleManagerState!.currentModule!.saveModule()
  //     .then((res) => {
  //       this.$store.dispatch('updateChatState', res.data)
  //         .then(() => {
  //           if (!currentModuleExistsInChat) {
  //             successNotification(this.$t('module_notification_module_add').toString())
  //           } else {
  //             successNotification(this.$t('module_notification_module_save').toString())
  //           }
  //
  //           // EXACT
  //           // ModuleManagerState!.clearCurrentModule()
  //         })
  //
  //     })
  // }

  handleSaveModule() {
    this.$baseTemplate.loader.open()

    this.saveModule()
      .then(() => {
        this.$baseTemplate.loader.close()

      })
    // .then(() => {
    //   this.$router.push({ name: 'ActiveModules' })
    // })
  }

  // beforeRouteLeave(from: Route, to: Route, next: NavigationGuardNext): void {
  //   if (ModuleManagerState) {
  //     if (ModuleManagerState.isCurrentModuleChanged) {
  //       this.$confirm({
  //         title: this.$t('module_confirm_save_before_leave_title').toString(),
  //         content: this.$t('module_confirm_save_before_leave_content').toString(),
  //         okText: this.$t('save_button').toString(),
  //         onOk: () => {
  //           this.saveModule()
  //             .finally(() => {
  //               next()
  //             })
  //         },
  //         onCancel: () => {
  //           ModuleManagerState!.clearCurrentModule()
  //
  //           next()
  //         }
  //       })
  //     } else {
  //       ModuleManagerState!.clearCurrentModule()

  //       next()
  //     }
  //   } else {
  //     next()
  //   }
  // }

  // created() {
  //   const moduleGuid = this.$route.params.moduleGuid
  //   const gotoCatalog = () => this.$router.push({
  //     name: 'ModulesCatalog'
  //   })
  //
  //   if (moduleGuid) {
  //     if (ModuleManagerState && !ModuleManagerState.currentModule) {
  //       const module = ModuleManagerState.getModuleByCriteria('guid', moduleGuid.toString())
  //
  //       if (module) {
  //         ModuleManagerState.prepareModuleForEdit(module)
  //       } else {
  //         errorNotification(this.$t('module_notification_module_not_found').toString())
  //
  //         gotoCatalog()
  //       }
  //     }
  //   } else {
  //     gotoCatalog()
  //   }
  // }

  mounted() {
    this.$baseTemplate.saveButton.hide()
    // this.$baseTemplate.saveButton.show(this.handleSaveModule)
  }
}
